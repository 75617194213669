@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.paymentBody {
  padding: 20px 0;
  background: #fafafa;
  min-height: 100vh;
}

.paymentForm .icon {
  background: url(/static/media/payment.682a71fb.svg);
  width: 30px;
  height: 30px;
}

.paymentBody .paymentForm .cardPhoto {
  position: absolute;
  width: 36px;
  height: 26px;
  right: 4px;
  top: 20px;
  background-position: -198px -66px;
}

.paymentBody .paymentForm .cardPhoto.VISA {
  background-position: -9px -66px;
}

.paymentBody .paymentForm .cardPhoto.MASTER {
  background-position: -67px -66px;
}

.paymentBody .paymentForm .cardPhoto.AMEX {
  background-position: -9px -105px;
}

.paymentBody .paymentForm .cardPhoto.MAESTRO {
  background-position: -68px -105px;
}

/* Chrome, Safari, Edge, Opera */
.paymentBody .paymentForm input::-webkit-outer-spin-button,
.paymentBody .paymentForm input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.paymentBody .paymentForm input[type=number] {
  -moz-appearance: textfield;
}

.paymentBody .form-row>.col,
.paymentBody .form-row>[class*=col-] {
  padding: 0;
}

.paymentBody .form-row {
  margin: 0;
  padding-bottom: 100px;
}

.paymentBody .form-actions {
  position: fixed;
  width: 100%;
  padding-bottom: 30px;
  background: #fff;
  bottom: 0;
}

.paymentBody .paymentForm {
  width: 375px;
  max-width: 100%;
  font-family: 'Montserrat', sans-serif;
  margin: 0 auto;
  background: #fff;
  padding: 20px 20px 40px;
  height: calc(100vh);
  position: relative;

}

.paymentBody .paymentForm ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #898989 !important;
  font-weight: 400;

}

.paymentBody .paymentForm input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ccc !important;
}

.paymentBody .paymentForm .form-group {

  position: relative;
  margin-bottom: 20px;
  padding: 8px 0 0px 0px;
  height: 58px;
  border-bottom: 2px solid #CECECE;
}

.paymentBody .paymentForm .form-group.invalid {
  border-color: red;
}


.paymentBody .paymentForm .form-group label {
  line-height: 17px;
}

.text-Cancel {
  text-decoration: line-through;
}

.paymentBody .paymentForm .form-group .iconBg {
  width: 54px;
  height: 54px;
  background: #d5fff0;
  position: absolute;
  left: 6px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  top: 6px;
}

.paymentBody .paymentForm .form-group:after {
  display: none;
}

.paymentBody .paymentForm .form-group input {
  background: transparent;
  border: 0;
  padding: 0;
}

.paymentBody .paymentForm .form-group label {
  display: block;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  color: #ADAEBA;
}

.paymentBody .paymentForm .form-group .form-control {
  border: 0;
  background: transparent;
  font-size: 15px;
  display: block;
  width: 100%;
  height: 34px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.paymentBody .paymentForm .errorMsg {
  color: red;
  margin: 4px 0 0;
  font-size: 10px;
}

.paymentBody .paymentForm .cardImg {
  position: absolute;
  right: 0;
  top: 36px;
}

.paymentBody .paymentForm .form-control:focus {
  box-shadow: none;
  outline: 0;
  border: 0;
}

.priceLabel h4 {
  font-size: 20px;
  padding: 6px 0 00 0;
  font-weight: 600;
}

.paymentBody .paymentForm .form-check {
  padding-left: 35px;
  margin: 30px 0 30px 20px;
  font-size: 14px;
}

.paymentBody .paymentForm .form-check-input {
  position: absolute;
  margin-top: 1px;
  margin-left: 0;
  width: 20px;
  height: 20px;
  left: 0;
}

.paymentBody .paymentForm .form-check-label {
  margin-bottom: 0;
  font-weight: 500;
}

.paymentBody .paymentForm .tiptext {
  color: #2EC8E3;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 10px;
}

.paymentBody .paymentForm .progress-bar {
  background-color: #151D56;
  color: #fff;
  height: 60px;
  line-height: 60px;
  padding: 0 30px;
  border: 0;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: calc(100% - 40px);
  -webkit-align-items: center;
          align-items: center;
  outline: 0;
  max-width: 335px;


}

.paymentBody .paymentForm .progress-bar.progress-bar-striped {
  pointer-events: none;
}

.paymentBody .paymentForm .progress-bar:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.paymentBody .paymentForm .progress-bar:hover {
  background-color: #0d1c8b;
}

.paymentBody .paymentForm .progress-bar.disabled {
  opacity: .5;
  pointer-events: none;
}

.paymentBody .paymentForm .cvc-tooltip {
  width: 290px;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: -124px;
  background: #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 22%);
  border-radius: 4px;
  padding: 10px 10px;
  display: none;
  font-weight: 400;
  line-height: 21px;
  color: #808080;
  letter-spacing: 0.3px;
}

.paymentBody .paymentForm .tiptext:hover+.cvc-tooltip {
  display: block;
}

.paymentBody .paymentForm .loaderSec {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99;
}

.paymentBody .paymentForm .loaderSec.open {
  display: block;
}

.tabHeader {
  padding: 0;
  display: -webkit-flex;
  display: flex;
  list-style: none;
  margin: 0 0 20px;
  padding-bottom: 20px;
  border-bottom: 4px solid #F2F3F9;
}

.tabHeader li {
  background: #F6F6F9;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  color: #1d143b;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  margin: 0 15px 0 0;
  width: -webkit-max-content;
  width: max-content;
  text-align: center;
  cursor: pointer;
}

.tabHeader li.active {
  background: #17C9E6;
  color: #fff;
}

.tabHeader li.active:hover {
  background: #17C9E6;
}

.tabHeader li:hover {
  background: #eeeeee;
}


.paymentBody .checkBlock {
  width: -webkit-max-content;
  width: max-content;
  text-align: left;
  padding: 12px 15px;
  width: 100%;
  border: 1px solid #CECECE;
  border-radius: 5px;
  color: #58585C;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  font-size: 14px;
}

.paymentBody .checkBlock.active {
  border: 1px solid #2BC9E4;
  box-shadow: 0 0 0px 1px #2bc9e4;
  background: #F4FDFF;
}

.paymentBody .checkBlock:hover {
  background: #F4FDFF;
}

.paymentBody .row {
  display: -webkit-flex;
  display: flex;
  margin: 0 -15px;
}

.paymentBody .row .col-6 {
  padding-right: 15px;
  padding-left: 15px;
}

.paymentBody .checkBlock .check:before {
  content: "";
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 10px;
  width: 5px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.paymentBody .checkBlock .check {
  width: 24px;
  height: 24px;
  border: 1px solid #ebebeb;
  border-radius: 50%;
  margin-right: 0px;
  position: relative;
  cursor: pointer;
  background: #2BC9E4;
  position: absolute;
  right: 15px;
  top: 10px;
  text-align: center;
  display: none;
}

.paymentBody .checkBlock.active .check {
  display: block;
}

.paymentRedirect .loading {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
}

.paymentRedirect .spinner-border {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 4px solid currentColor;
  border-right-color: transparent;
}

.failSec {
  text-align: center;
}

.failSech6 {
  margin-bottom: 20px;
}

.btn-primary {
  padding: 8px 25px;
  border-radius: 30px;
  border: 0;
}

.paymentBody h3 {
  margin-bottom: 20px;
}

.paymnetFailBlock {
  text-align: center;
}



.paymentBody .paymentForm .cashPaybutton  .progress-bar {width: 100%;}
.paymentBody .paymentForm .progress-bar.invalid{opacity: .4;pointer-events: none;}

@media only screen and (max-width: 360px) {
  .tabHeader li {padding: 0 10px;}
}
